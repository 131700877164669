import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import bg from '../Img/bg-texture.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import logo from '../Img/logo-tra.png'

export default function Navbar() {

    const [menu, setMenu] = useState(0);
    const [icon, setIcon] = useState('menu');
    const menuButton = () =>{
        if (menu === 0) {
            setMenu(1);
            setIcon('close')
          }
          else{
            setMenu(0);
            setIcon('menu')
          }
        }
        const closeMenu = () =>{
          if(menu === 1){
            setMenu(0);
            setIcon('menu')
          }
    }


  return (
    <div style={{position : "fixed", zIndex: 3, background: `url("https://i.ibb.co/tbrzcfG/bg-texture.jpg")`, backgroundRepeat:'repeat-x', backgroundPosition:'top'}}  onClick={closeMenu} className='px-3 md:px-10 top-0 w-full  py-3 md:py-1 flex items-center justify-between  shadow-lg '>
        <a href="/" className='flex'><LazyLoadImage effect='blur' className=' logo w-[70vw] max-w-[400px]' src="https://i.ibb.co/sWp9vTh/1680975605910-removebg-preview.png" alt="" /></a>
        <span className="md:hidden cursor-pointer material-symbols-outlined" onClick={menuButton}>{icon}</span>
        <div style={{zIndex: 0, background : `url(${bg})`}} className={menu===0?"md:flex 0 w-full  md:shadow-none  left-0 md:py- p-  justify-end  md:bg-transparent rounded-lg shadow-lg duration-300 md:static absolute -top-[100vh]":"md:flex 0 w-full  md:shadow-none duration-300  left-0 md:p-0 p-5 justify-end  rounded-lg  shadow-lg md:static absolute top-14"}>

        <ul className='flex text-sm md:flex-row flex-col items-center mr-5 justify-evenly'>
            <Link to='/' className='block cursor-pointer  mx-5 my-3 md:my-0 md:hover:opacity-100 opacity-75 duration-200 md:hover:opacity-100'>Home</Link>
            <Link to='/projects' className='block cursor-pointer  mx-5 my-3 md:my-0 md:hover:opacity-100 opacity-75 duration-200 md:hover:opacity-100'>Projects</Link>
            <Link to='/about' className='block cursor-pointer  mx-5 my-3 md:my-0 md:hover:opacity-100 opacity-75 duration-200 md:hover:opacity-100'>About</Link>
        </ul>
        <Link to='/contactus' className=' text-center text-sm block w-full md:w-auto   px-4 py-2 bg-[#832e2e] hover:bg-[#bb4141]  h active:ring active:ring-offset-2 active:ring-[#832e2e] shadow-lg hover:shadow-none text-white rounded-full duration-100'   >Contact Us</Link>
        <p className='md:hidden opacity-25 text-sm text-center pt-5'>Click to close</p>
        </div>
    </div>
  )
}
