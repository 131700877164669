import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9tt1lqp', 'template_62vajbj', form.current, 'Z7W3_g_CJGT4RlNu6')
      .then((result) => {
          console.log(result.text);
          alert("email sent!");
        }, (error) => {
          console.log(error.text);
          alert("Error in sending!");
      });
  };

  return (
    <div className='py-12 min-h-screen   text-[#252525] px-5 md:px-24' style={{background : `url("https://i.ibb.co/hC4MBsf/BACKGROUND-NEW.jpg")`}} >
      <h1 className='text-[40px] text-center py-8 font-semibold'>We would love to connect with YOU</h1>
    <section className="contactt flex flex-col-reverse md:flex-row justify-around md:justify-around ">
      <div className="left">
        <div className='flex justify-center'>
        <img src="https://i.imgur.com/ogQZePD.png" className='max-w-[80%] pt-8 md:py-0' alt="" />

        </div>
      </div>
      

      <div className=' w-full md:w-[80%] max-w-[540px]  border-[#832e2e] py-4 px-8 border-2 rounded-md'>
        <h2 className='text-[25px] text-center text-[#832e2e]'>Write us Email now</h2>
      <form ref={form} onSubmit={sendEmail} className='grid grid-cols-1'>
      <label>Name</label>
      <input type="text" required name="from_name" className='p-2 border-2 border-slate-400 rounded-md mb-4' />
      <label>Email</label>
      <input type="email" required name="from_email" className='p-2 border-2 border-slate-400 rounded-md  mb-4' />
      <label>Message</label>
      <textarea name="message" required rows={5} className='p-2 border-2 border-slate-400 rounded-md  mb-4' />
      <input type="submit" value="Send" className='cursor-pointer bg-[#832e2e] mx-12 my-4 p-3 active:ring-2 active: ring-offset-2 duration-200 active:ring-red-600 rounded-sm text-white text-2xl  hover:bg-[#b13f3f] '/>
    </form>
      </div>
    </section>
    </div>
  );
};

export default ContactUs