import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Footer from './Footer';


export default function Projects() {
//   const [thumbsSwiper, setThumbsSwiper] = useState("https://i.imgur.com/0fL8OgC.jpg");
  return (
    <div  className='lg:px-32 px-6 py-10 pt-20 text-[#252525]' style={{background : `url("https://i.ibb.co/hC4MBsf/BACKGROUND-NEW.jpg")`}} >
        <h1 className=' text-4xl lg:text-[45px] text-[30px] text-center font-semibold pb-8'>OUR PROJECTS</h1>
        <div className='border-4  border-[#832e2e] py-6 px-4 lg:p-10 rounded-lg'>

      <section className="lg:flex justify-between ">
        <h1 className=' text-xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Government Project 01</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/9WBHfhW/21-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/tHMwr4K/8-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/2vr366d/1-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/JRKyKMB/2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/vj0d9Zf/3-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/cCYV6hm/4-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/nmRj3kt/5-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/GsvWNWv/6-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/C6Ckw1K/7-min.jpg" /></div>
        </div>
      </section>
      
      <section className="lg:flex justify-between pb-">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Government Project 02</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/yqStWpY/20-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/KjyNnKs/2-min-1.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/KDnrC0R/4-min-1.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/BZPQKND/5-min-1.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/gJ3H8Xm/6-min-1.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/fMrJxYN/7-min-1.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/Hd6WP7S/8-min-1.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/19PQzWv/9-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/kBdQFSF/10-min.jpg" /></div>
        </div>
      </section>
      </div>

      <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Banquet Designs</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/86fF0Fy/B1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/qJJ5HBs/A4-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/vd4HG87/B2-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/YdJ7qXd/B3-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/r6JmpSc/B4-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/Xx7nFfJ/B5-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/wsvs1vZ/B7-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/m9f9zjt/B6-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/QvwWMt6/B8-min.jpg" /></div>
        </div>
      </section>
      
      <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Bathroom Design</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
        <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/QCgPX96/P2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/7yM0XCR/K2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/0nt1XSz/L1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/2StcNDC/L2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/MZW8QcV/M1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/fYy9t7Z/N1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/GVTpQsr/O1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/WPw9274/O2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/wwt9zNf/P1-min.jpg" /></div>
        </div>
      </section>
      <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Bedroom Design</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src= "https://i.ibb.co/b5Ggn6N/C1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src= "https://i.ibb.co/9ctZYnF/C2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/6w6g37c/D1-min.jpg"/></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src= "https://i.ibb.co/QvjvWBj/D2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src= "https://i.ibb.co/vHWvy46/D3-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src= "https://i.ibb.co/mvs7XKr/E1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/Y2WNFsq/E2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/1fsH8GV/E3-min.jpg"/></div>
            <div className='self-center'><LazyLoadImage className='items-self-center'    effect="blur" src="https://i.ibb.co/9Ykk7gV/S1-min.jpg" /></div>
        </div>
      </section>
      <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Kids Room</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/CtkH1Rf/F2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/1rmhnYy/F1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/2NMGMW0/H1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/JQ0bvwp/G1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/th9QTZk/H2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/d24sqTZ/I1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/7rrwCVy/I2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/NWXC3Fw/J1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/HKJ0XwY/J2-min.jpg" /></div>
        </div>
      </section>
      <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Kitchen Design</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/FXXvh5m/1-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/q5CJQjc/2-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/3rkJPWZ/3-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/CVQS4B5/4-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/PFNG6nM/5-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/TPtpk8f/6-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/wNSh70K/7-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/1TVCPK9/Z2-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/Z8jB9Cp/Z3-min.jpg"  /></div>
        </div>
      </section>
      <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Living Room</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/37KD3LW/W7-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/M9H5wzP/W6-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/y6HyKWm/X1-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/G71RW12/W8-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/c1b4389/X2-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/yQrVx73/X3-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/FXdH6Bd/Y1-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/wsqcjR8/W4-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/Np8yxT3/W5-min.jpg" /></div>
        </div>
      </section>
      <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Office Design</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/Fbt0bVC/4-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/JBfS0fc/3-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/0qVmPd9/5-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/KLmP9RB/6-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/VT2vfKR/7-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/3NFhGHL/8-min.jpg" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/VvJfhF3/9-min.jpg"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/fHbRxZD/10-min.jpg"   /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https://i.ibb.co/FWPBDrw/11-min.jpg"  /></div>
        </div>
      </section>
      {/* <section className="lg:flex justify-between p-5 md:py-10 my-10 rounded-lg border-4  border-[#832e2e]">
        <h1 className=' text-2xl  text-center lg:text-right lg:text-[30px] leading-tight font- lg:w-[20%] px-6'>Government Project1</h1>
        <hr  className='w-full bg-black h-1 opacity-25 rounded-full lg:hidden my-3'/>
        <div className='w-[3px]  bg-black opacity-50 rounded-full hidden lg:block'>.</div>
        <div className='grid lg:grid-cols-3 gap-3 lg:w-[80%] px-6'>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https" /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https"  /></div>
            <div className='self-center'><LazyLoadImage    effect="blur" src="https" /></div>
        </div>
      </section> */}
            {/* <Footer/> */}
    </div>
  )
}
