import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'

export default function About() {
  return (
    <div  className='grid grid-cols-1 justify-items-center pt-20 md:pt-32 flex px-6 lg:px-40 b0 text-[#252525]' style={{background : `url("https://i.ibb.co/hC4MBsf/BACKGROUND-NEW.jpg")`}} >
    <section className="md:flex justify-around items-center max-w-[1000px] pb-10 md:pb-20">
      <div className='md:w-[66%]  '>
        {/* <h1 className=' text-[40px] text-center md:text-left md:text-[50px] leading-[55px] pb-5   font-semibold '>WHO ARE WE?</h1> */}
        <p className='tracking-wide mb-4 md:text-2xl leading-relaxed'>Welcome to our Interior 3D Designing website! We are a team of skilled and passionate professionals who are dedicatedto providing our clients with high-quality interior renders that bring their design visions to life.</p>

      </div>
      <aside className='md:w-[33%] flex justify-center '>
        <img src="https://i.imgur.com/9MAoyam.png" className=' w-[80%] shadow-xl rounded-md' alt="" />
      </aside>
    </section>
    
    <section className="grid grid-cols-1 justify-items-center  max-w-[1000px] pb-8 lg:pb-20">
      <div className='  md:px-8'>
        <h1 className=' text-[40px] text-center  md:text-[50px] leading-[55px] md:pb-5   font-semibold  '>WHO ARE WE?</h1>
        <p className='opacity-75 text-justify tracking-wide text-xs md:text-base md:leading-loose mb-3'>Our team is made up of experienced architects, designers, and 3D artists who specialize in creating photo realistic designs of interior spaces. We work closely with our clients to understand their unique design preferences, and we usestate-of-the-art technology and software to create renders that accurately reflect their vision.</p>

        <p className='opacity-75 text-justify tracking-wide text-xs md:text-base md:leading-loose mb-3'>
        At our core, we are committed to delivering exceptional customer service. We understand that every client has different needs and expectations, and we work tirelessly to ensure that we exceed those expectations at every stage of theproject. From the initial consultation to the final delivery of the project, we are dedicated to ensuring that our clients are completely satisfied with our work.</p>
        <p className='opacity-75 text-justify tracking-wide text-xs md:text-base md:leading-loose'>
        we believe that great design should be accessible to everyone, and we are committed to delivering exceptional results at a fair price. Whether you are looking to refresh a single room or undertake a complete renovation, we are here to help you create the home of your dreams. </p>

      </div>
        <img src="https://i.ibb.co/YZnS107/14620625-5484597-removebg-preview.png" alt="" />
    </section>
    <Link to="/contactus" className='text-center text-white bg-[#832e2e]  md:text-left md:text-[30px] md:leading-[50px] p-5 rounded-lg hover:scale-105 duration-200 shadow-lg py-3 md:leading-[55px] mb-16   font-semibold '>Liked Our story? Make your space better</Link>
<p className='mb-16 tracking-wide md:text-2xl leading-relaxed'>Thank you for considering our services for your interior designing needs. We look forward to the opportunity to workwith you and bring your design vision to life!</p>
</div>
  )
}
