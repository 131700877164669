import React, {useEffect, useState} from 'react'
// import logos from './Img/homebg.jpg'   
import {Link} from 'react-router-dom'
import Footer from './Footer';

import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/autoplay"
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Home() {
  
  const [width, setWidth] = useState(window.innerWidth);
  const [widthData, setWidthData] = useState({slidesPerViewUp : 4, spaceBetweenUp : 100, slidesPerViewDown : 2, spaceBetweenDown : 100});
  const updateWindowWidth = () =>{
    setWidth(window.innerWidth);
    // console.log(width);
    if(width <= 760){
      setWidthData({
        slidesPerViewUp : 2,
        spaceBetweenUp : 50,
        slidesPerViewDown : 1, 
        spaceBetweenDown : 50
      })
    }
    else{
      setWidthData({slidesPerViewUp : 4, spaceBetweenUp : 100, slidesPerViewDown : 2, spaceBetweenDown : 100})
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);

    return () =>  {
      window.removeEventListener("resize", updateWindowWidth);
    }
  });


  return (
    <div className='text-[#252525]  bg-repeat-x' style={{background : `url("https://i.ibb.co/hC4MBsf/BACKGROUND-NEW.jpg")`}} >
      {/* <section style={{backgroundImage  : `url("https://imgur.com/OBjkGoO.jpeg")`}} className="hero px-6 pb-8 lg:pb-auto lg:px-36 bg-center bg-cover bg-no-repeat h-[95vh] md:h-[100vh] grid  grid-rows-6 gap-3   ">
          <h1 className=' text-center row-start-2 font-semibold md:text-[55px] sm:text-[45px] text-[10vw] pt-0 md:pt-10  leading-tight sm:leading-[50px] md:leading-[70px] '>Elevate Your Interiors With<br/><span className='text-[#832e2e]'>Expert Design Services</span></h1>
          <div className="grid md:grid-cols-3 row-start-4 md:row-start-5 sm:pt-10 gap-3 sm:gap-5 text-xs sm:text-sm md:text-lg ">
            <div className='bg-[#ffffffd4] rounded-xl shadow-2xl p-3 px-4 sm:p-5 sm:px-6 flex items-center'><span className="text-[#832e2e] pr-4 md:text-[40px] material-symbols-outlined">auto_awesome</span><p >Providing personalized attention to each customer is the key to creating a tailored experience</p></div>
            <div className='bg-[#ffffffd4] rounded-xl shadow-2xl p-3 px-4 sm:p-5 sm:px-6 flex items-center'><span className="text-[#832e2e] pr-4 md:text-[40px] material-symbols-outlined">record_voice_over</span><p>Clear communication is essential match the customer's vision and final design</p></div>
            <div className='bg-[#ffffffd4] rounded-xl shadow-2xl p-3 px-4 sm:p-5 sm:px-6 flex items-center'><span className="text-[#832e2e] pr-4 md:text-[40px] material-symbols-outlined">brush</span><p>Experienced designers will provide the best experience by delivering outstanding results</p></div>
            </div>
           </section> */}
      <section style={{backgroundImage  : `url("https://imgur.com/OBjkGoO.jpeg")`}} className="hero px-6 pb-8 lg:pb-auto lg:px-36 bg-center bg-cover bg-no-repeat h-[95vh] md:h-[100vh] max-h-[1200px] flex flex-col justify-between md:justify-evenly ">
          <h1 className=' text-center font-semibold md:text-[60px] text-[10vw] pt-[15vh] md:pt-0  leading-[12vw] md:leading-[70px] '>Elevate Your Interiors With<br/><span className='text-[#832e2e]'>Expert Design Services</span></h1>
          <div className="grid md:grid-cols-3  sm:pt-10 gap-3 sm:gap-5 text-sm sm:text-base md:text-lg">
            <div className='bg-[#ffffffd4] rounded-xl shadow-2xl p-3 px-4 sm:p-5 sm:px-6 flex items-center'><span className="text-[#832e2e] pr-4 md:text-[40px] material-symbols-outlined">auto_awesome</span><p>Providing personalized attention to each customer is the key to creating a tailored experience</p></div>
            <div className='bg-[#ffffffd4] rounded-xl shadow-2xl p-3 px-4 sm:p-5 sm:px-6 flex items-center'><span className="text-[#832e2e] pr-4 md:text-[40px] material-symbols-outlined">record_voice_over</span><p>Clear communication is essential match the customer's vision and final design</p></div>
            <div className='bg-[#ffffffd4] rounded-xl shadow-2xl p-3 px-4 sm:p-5 sm:px-6 flex items-center'><span className="text-[#832e2e] pr-4 md:text-[40px] material-symbols-outlined">brush</span><p>Experienced designers will provide the best experience by delivering outstanding results</p></div>
            </div>
           </section>
      <div className="swip px-6 md:px-14 py-6  lg:py-16  rounded-2xl ">

     
      <h2 className='text-center  tracking-widest text-[#832e2e] text-xs lg:text-sm font-semibold '>TRUSTED BY</h2>
      <h2 className='text-center  text-slate-800 text-[25px]  md:text-[40px] font-semibold '>We have worked with</h2>
      <Swiper 
              slidesPerView={widthData.slidesPerViewUp}
              spaceBetween={widthData.spaceBetweenUp}
              centeredSlides={false}
              speed={500}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={false}
              navigation={false}
              // loop={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper mt-4 md:mt-8 ">
        
        <SwiperSlide className='    rounded-2xl'><div className='   flex items-center  duration-300'><LazyLoadImage className='px-3 lg:px-12' src="https://i.ibb.co/2gmwX7L/LCS-LOGO.jpg" alt="" /></div></SwiperSlide>
        <SwiperSlide className='    rounded-2xl'><div className='   flex items-center  duration-300'><LazyLoadImage effect='blur' className=' px-3 lg:px-12' src="https://i.ibb.co/3st8Zzb/EASTERN-RAILWAY.jpg" alt="" /></div></SwiperSlide>
        <SwiperSlide className='    rounded-2xl'><div className='   flex items-center  duration-300'><LazyLoadImage effect='blur' className='   px-3 lg:px-12' src="https://i.ibb.co/Tb4XvXv/CREAZIONE.jpg" alt="" /></div></SwiperSlide>
        <SwiperSlide className='    rounded-2xl'><div className='   flex items-center  duration-300'><LazyLoadImage effect='blur' className='px-3 lg:px-12 ' src="https://i.ibb.co/Vg4dgzz/IMG-20230405-WA0002.jpg" alt="" /></div></SwiperSlide>
        <SwiperSlide className='    rounded-2xl'><div className='   flex items-center  duration-300'><LazyLoadImage effect='blur' className='px-3 lg:px-12 ' src="https://i.ibb.co/VQT0fgx/Rationallogo-new.jpg" alt="" /></div></SwiperSlide>
        <SwiperSlide className='    rounded-2xl'><div className='   flex items-center  duration-300'><LazyLoadImage effect='blur' className='px-3 lg:px-12 ' src="https://i.ibb.co/pWV3H3Z/TNT-LOGO.jpg" alt="" /></div></SwiperSlide>
        
        
      </Swiper>
      </div>


      <section className='px-6 py-6 md:py-8'>
                <h2 className='text-center  tracking-widest text-[#832e2e] text-xs lg:text-sm font-semibold '>PROOF OF WORK</h2>
                <h2 className='text-center  text-slate-800 text-[25px] md:text-[40px] font-semibold '>Here's what we have made</h2>
                <div className="pt-4 md:pt-8">

      <Swiper
        slidesPerView={widthData.slidesPerViewDown}
        spaceBetween={widthData.slidesPerViewDown}
        speed={800}
        centeredSlides={false}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={false}
        navigation={false}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper "
        >
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className='  px-6 '  effect="blur" src="https://i.ibb.co/qJ14sWk/kids-room.jpg"      /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className='  px-6 '  effect="blur" src="https://i.ibb.co/5B0wCtR/living-room.jpg"      /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/PgjBYxz/dining-room.jpg"      /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/Y2cdSrM/bedroom-1.jpg"    /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/DGcnr0j/bedroom-2.jpg"     /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/6g0zmRd/kitchen.jpg"    /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/QKFWvHm/office-2.jpg"     /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/vHkNzPR/office-1.jpg"   /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/MRxwKVD/office-3.jpg" /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/NtQC1tj/office-4.jpg" /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/5M3bgd4/office-5.jpg" /></div> </SwiperSlide>
        <SwiperSlide className='self-center'><div className='self-center'><LazyLoadImage  className=' px-6'  effect="blur" src="https://i.ibb.co/VQG0kWj/bedroom-3.jpg" /></div> </SwiperSlide>
        
      </Swiper>
        </div>
           </section>
            
      {/* <section className="benefits px-6 py-8">
        <h2 className='text-center  tracking-widest text-[#832e2e] text-sm font-semibold '>BENEFITS</h2>
        <h2 className='text-center  text-slate-800 text-[30px] md:text-[40px] font-semibold pb-5'>What do you get?</h2>
        <div className='grid md:grid-cols-3 gap-6 md:mx-10 md:mb-20'>
          <div className="box text-center  bg-white p-5 pb-7 rounded-lg shadow-lg">
          <span className="material-symbols-outlined opacity-75 text-[50px] text-red-800 ">verified</span>
            <h3 className='text-lg font-semibold '>Heading 1</h3>
            <p className='text-sm'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur ut maxime vero atque totam praesentium.</p>
          </div>
          <div className="box text-center  bg-white p-5 pb-7 rounded-lg shadow-lg">
          <span className="material-symbols-outlined opacity-75 text-[50px] text-red-800 ">verified</span>
            <h3 className='text-lg font-semibold '>Heading 1</h3>
            <p className='text-sm'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur ut maxime vero atque totam praesentium.</p>
          </div>
          <div className="box text-center  bg-white p-5 pb-7 rounded-lg shadow-lg">
          <span className="material-symbols-outlined opacity-75 text-[50px] text-red-800 ">verified</span>
            <h3 className='text-lg font-semibold '>Heading 1</h3>
            <p className='text-sm'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur ut maxime vero atque totam praesentium.</p>
          </div>
        </div >
      </section> */}

      <section className="experience md:flex justify-center items-center lg:mx-36 px-8 md:my-28 my-8">
        <div className="md:w-[50%]  rounded-lg h- text-right flex justify-center md:justify-end mx-6 mb-8 md:mb-0 md:m-0"><LazyLoadImage  className=' '  effect="blur" src="https://i.ibb.co/5B0wCtR/living-room.jpg" /></div>
        <div className="text md:w-[50%] md:ml-10">
          <h2 className='text-[25px] md:text-[40px] leading-loosen md:leading-[60px] font-semibold md:mb-5 mb-3 md:pl-2  '>We provide you the <br/><span className='bg-[#ff5959a6] px-1'>Best Experience</span></h2>
          <p className='opacity-75 text-xs md:text-sm md:leading-relaxed md:w-[80%] md:ml-2 mb-5 text-justify'>At our company, we pride ourselves on providing the best client experience possible. We achieve this through personalised attention, taking the time to understand our clients' unique needs and goals. Our creative solutions are tailored to meet each individual requirement. We ensure clear communication throughout the process, so our clients are informed every step of the way. We only use quality products and materials, and our professionalism and expertise ensure that we always deliver beyond expectations.</p>
          <div className="flex work ">
            <div className='pl-2 text-center mr-2 md:mr-16'>
              <h3 className='font-semibold md:text-[40px]'>100+</h3>
              <p className='text-xs'>Projects<br/>completed</p>
            </div>
            <div className='pl-2 text-center mr-2 md:mr-16'>
              <h3 className='font-semibold md:text-[40px]'>100K+</h3>
              <p className='text-xs'>Designs<br/>created</p>
            </div>
            <div className='pl-2 text-center mr-2 md:mr-16'>
              <h3 className='font-semibold md:text-[40px]'>100+</h3>
              <p className='text-xs'>Clients<br/>satisfied</p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="experience  px-8 lg:mx-36 md:my-20 py-8">
        <div className='md:flex justify-center items-center'>
        <div className="text md:w-[50%] md:mr-10">
          <h2 className='text-[25px] md:text-[40px] md:leading-[60px] leading-loosen font-semibold mb-5 md:mb-10 pl-2  '>We go through an <br/><span className='bg-[#ff5959a6] px-1'>Optimal Process</span></h2>
          
          <div className="flex work mb-2 md:mb-5">
              <p className='font-semibold mx-4 bg-slate-400  h-10 text-white p-2 rounded-full  '>01</p>
              <p className='text-sm w-[75%]  text-justify'><strong className='text-base'>Conceptualization</strong> : The first stage involves brainstorming, where the designer gets an idea and creates a rough sketch of the design.</p>
          </div>
          <div className="flex work mb-2 md:mb-5">
              <p className='font-semibold mx-4 bg-slate-400  h-10 text-white p-2 rounded-full  '>02</p>
              <p className='text-sm w-[75%] text-justify    '><strong className='text-base'>3D modeling</strong> : the designer uses 3D modeling software to create a digital modelof the design to see how different elements of the design fit together in realtime</p>
          </div>
          <div className="flex work mb-2 md:mb-5">
              <p className='font-semibold mx-4 bg-slate-400  h-10 text-white p-2 rounded-full  '>03</p>
              <p className='text-sm w-[75%] text-justify    '><strong className='text-base'>Lighting and Materials</strong> :  Once the basic model is created, the designer can add lighting and materials to the design togive it a more realistic look and feel</p>
          </div>
          <div className="flex work mb-2 md:mb-5">
              <p className='font-semibold mx-4 bg-slate-400  h-10 text-white p-2 rounded-full  '>04</p>
              <p className='text-sm w-[75%] text-justify    '><strong className='text-base'>Texturing and Rendering</strong> :  The designer applies textures to the surfaces and render the final image. This is where the design begins to come to life.</p>
          </div>
          <div className="flex work mb-2 md:mb-5">
              <p className='font-semibold mx-4 bg-slate-400  h-10 text-white p-2 rounded-full  '>05</p>
              <p className='text-sm w-[75%] text-justify    '><strong className='text-base'>Presentation</strong> :  The designer uses a variety of tools toshowcase the design, such as virtual reality or walk through videos, to give the client a more immersive experience.</p>
          </div>
        </div>
        <div className="md:w-[50%]  rounded-lg h text-right flex justify-end mx-4 md:m-0"><LazyLoadImage  className=' -6 '  effect="blur" src="https://i.ibb.co/5M3bgd4/office-5.jpg" /></div>
        </div>
      <p className='text-base md:text-lg lg:text-xl mt-5'>Overall, providing a personalized, creative, communicative, and professional experience can help create a successful interior designing project that satisfies the customer's desires and requirements.</p>
      </section>

      {/* <section className="md:my-16 mt-16 reviews px-6">
      <h2 className='text-center tracking-widest text-[#832e2e] text-sm font-semibold '>REVIEWS</h2>
        <h2 className='text-center  text-[30px] md:text-[40px] font-semibold pb-5 leading-tight'>What do our clients say?</h2>
      <div className="py-4 md:mx-32">

          <Swiper
            slidesPerView={widthData.slidesPerViewDown}
            spaceBetween={50}
            speed={200}
            centeredSlides={false}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={false}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper "
            >
            <SwiperSlide className='px md:px-0'> <div className="py-4 px-6 rounded-lg shadow-lg bg-white ">
              <h3 className='text-xl  bg-[#ff5959a6] font-semibold   inline-block mb-3'>Amazing Work Done</h3>
              <p className='text-sm text-justify opacity-75 mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, sapiente perferendis eligendi sequi id, illum illo ipsam sint veniam vitae, tempora reiciendis? Veniam earum ut libero qui modi, quibusdam rem?</p>
              <p className="text-right opacity-50 text-xs">- Samyak Jain</p>
            </div></SwiperSlide>
            <SwiperSlide  className='px-20 md:px-0'> <div className="py-4 px-6 rounded-lg shadow-lg bg-white ">
              <h3 className='text-xl  bg-[#ff5959a6] font-semibold   inline-block mb-3'>Amazing Work Done</h3>
              <p className='text-sm text-justify opacity-75 mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, sapiente perferendis eligendi sequi id, illum illo ipsam sint veniam vitae, tempora reiciendis? Veniam earum ut libero qui modi, quibusdam rem?</p>
              <p className="text-right opacity-50 text-xs">- Samyak Jain</p>
            </div></SwiperSlide>
            <SwiperSlide  className='px-20 md:px-0'> <div className="py-4 px-6 rounded-lg shadow-lg bg-white ">
              <h3 className='text-xl  bg-[#ff5959a6] font-semibold   inline-block mb-3'>Amazing Work Done</h3>
              <p className='text-sm text-justify opacity-75 mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, sapiente perferendis eligendi sequi id, illum illo ipsam sint veniam vitae, tempora reiciendis? Veniam earum ut libero qui modi, quibusdam rem?</p>
              <p className="text-right opacity-50 text-xs">- Samyak Jain</p>
            </div></SwiperSlide>
            <SwiperSlide  className='px-20 md:px-0'> <div className="py-4 px-6 rounded-lg shadow-lg bg-white ">
              <h3 className='text-xl  bg-[#ff5959a6] font-semibold   inline-block mb-3'>Amazing Work Done</h3>
              <p className='text-sm text-justify opacity-75 mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, sapiente perferendis eligendi sequi id, illum illo ipsam sint veniam vitae, tempora reiciendis? Veniam earum ut libero qui modi, quibusdam rem?</p>
              <p className="text-right opacity-50 text-xs">- Samyak Jain</p>
            </div></SwiperSlide>
            <SwiperSlide  className='px-20 md:px-0'> <div className="py-4 px-6 rounded-lg shadow-lg bg-white ">
              <h3 className='text-xl  bg-[#ff5959a6] font-semibold   inline-block mb-3'>Amazing Work Done</h3>
              <p className='text-sm text-justify opacity-75 mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam, sapiente perferendis eligendi sequi id, illum illo ipsam sint veniam vitae, tempora reiciendis? Veniam earum ut libero qui modi, quibusdam rem?</p>
              <p className="text-right opacity-50 text-xs">- Samyak Jain</p>
            </div></SwiperSlide>
            
          </Swiper>
            </div>
      </section> */}
      <section className="cta md:mx-16 px-12  md:flex justify-between rounded-lg items-center  py-4 ">
        
          <h2 className='text-[30px] leading-tight md:leading-auto md:text-[40px] font-semibold mb-5'>Transform your space today</h2>
          <Link to="/contactus" className=' inline-block px-9 py-6 bg-[#832e2e] w-full hover:scale-110 active:ring active:ring-2 active:ring-red-600 active:ring-offset-2 duration-300 md:w-auto rounded-full text-white text-lg text-center'>Book a Call Here!</Link>
        {/* </div> */}
        {/* <div className="photo"></div> */}
      </section>
      {/* <Footer/> */}
    </div>
  )
}
