import Navbar from "./Components/Navbar";
import Home from "./Components/Home";

import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Projects from "./Components/Projects";
import About from "./Components/About";
import ContactUs from "./Components/ContactUs";


import './style.css'
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
// import Projects2 from "./Components/Projects2";


function App() {
  return (
    <BrowserRouter >
        <Navbar/>
        <ScrollToTop/>
      <Routes >
        <Route path='/'  element={<Home/>}/>
        {/* <Route path='/'  element={<div>home</div>}/> */}
        <Route path='/projects' element={<Projects/>}/>
        {/* <Route path='/projects2' element={<Projects2/>}/> */}
        {/* <Route path='/projects' element={<div></div>}/> */}
        <Route path='/about' element={<About/>}/>
        <Route path='/contactus' element={<ContactUs/>}/>
        {/* <Route path='/about' element={<div>about</div>}/> */}
      </Routes>
      <Footer/>
    </BrowserRouter>

  );
}

export default App;
