import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Icon } from '@iconify/react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Footer() {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9tt1lqp', 'template_62vajbj', form.current, 'Z7W3_g_CJGT4RlNu6')
      .then((result) => {
          console.log(result.text);
          alert("email sent!");
        }, (error) => {
          console.log(error.text);
          alert("Error in sending!");
      });
  };
  return (
    <div className='p-4 px-6 md:px-16 bg-[#252525]  text-white grid gap-8 lg:gap-16 lg:grid-cols-3 grid-cols-1  '>
        <div className="head ">
            <h2 className='font-thin  mb-2  text-[40px]'>Abode Designs</h2>
            <p className="opacity-50 font-extralight tracking-wider text-sm mb-5  ">We are a team of experienced architects, designers, and 3D artists who specialize in creating photo realisticdesigns of interior spaces. We work closely with our clients to understand their unique design preferences, and we usestate-of-the-art technology and software to create renders that accurately reflect their vision.</p>
            <div className="links flex my-5">
                <a  href="https://www.facebook.com/abodedesigns.co.in" target='_blank' rel='noreferrer' className='w-[15%] block rounded-lg  md:opacity-75 hover:opacity-100 hover:scale-105 duration-200 mr-5 '><LazyLoadImage effect='blur' src="https://i.ibb.co/txSqrSq/FB.png" alt="FB" border="0"/></a>
                <a href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer' className='w-[15%] block rounded-lg  md:opacity-75 hover:opacity-100 hover:scale-105 duration-200 mr-5 '><LazyLoadImage effect='blur'  src="https://i.ibb.co/jhMpWC1/INSTA.png" alt="INSTA" border="0"/></a>
                <a  href="https://wa.me/+918910077021" target='_blank' rel='noreferrer' className=' block rounded-lg w-[15%] md:opacity-75 hover:opacity-100 hover:scale-105 duration-200 mr-5 '><LazyLoadImage effect='blur'  src="https://i.ibb.co/xsWXDxR/whatsapp.png" alt="whatsapp" border="0"/></a>
                </div>
                {/* <a  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer' className='p-5 block rounded-lg bg-[#1c99e6] md:opacity-75 hover:opacity-100 hover:scale-105 duration-200 mr-5 '><Icon icon="fe:twitter" className='text-white text-2xl '  /></a> */}
                {/* <a  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer' className='p-5 block rounded-lg bg-slate-400 mr-5'>lskf</a>
                <a  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer' className='p-5 block rounded-lg bg-slate-400 mr-5'>lskf</a>
                <a  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer' className='p-5 block rounded-lg bg-slate-400 mr-5'>lskf</a> */}
        </div>
        {/* <div className="links md:text-center">
            <h2 className=' opacity-75 font-thin mb-2 text-[30px]'>Links</h2>
            <div className='opacity-50'>
            <p  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer'>link</p>
            <p  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer'>link</p>
            <p  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer'>link</p>
            <p  href="https://www.instagram.com/abodedesigns.co.in/" target='_blank' rel='noreferrer'>link </p>
                </div>
            
        </div> */}
        <div className="address">
            <h2 className=' mb-2 font-thin text-[40px]'>Contact Us</h2>
            <p className="opacity-50 font-extralight tracking-wider text-sm pb-2">Howrah, West Bengal, India 711102</p>
            <p className="opacity-50 font-extralight tracking-wider text-sm pb-2">abodedesigns.co.in@gmail.com</p>
            <p className="opacity-50 font-extralight tracking-wider text-sm pb-2">089100 77021</p>
            
        
        <div className="links flex my-5">
                <a  href="mailto:abodedesigns.co.in@gmail.com" target='_blank' rel='noreferrer' className=' block rounded-lg w-[15%] text-2xl md:opacity-75 hover:opacity-100 hover:scale-105 duration-200 mr-5 '><LazyLoadImage effect='blur'  src="https://i.ibb.co/5kLhJ1p/MAIL.png" alt="MAIL" border="0"/></a>
                <a href="https://www.google.com/maps?q=Howrah,+West+Bengal,+India+711102" target='_blank' rel='noreferrer' className='w-[15%] block rounded-lg  text-3l md:opacity-75 hover:opacity-100 hover:scale-105 duration-200 mr-5 '><LazyLoadImage effect='blur'  src="https://i.ibb.co/b54VzbM/location.png" alt="location" border="0"/></a>
                <a  href='tel:089100 77021' target='_blank' rel='noreferrer' className=' block rounded-lg w-[15%] md:opacity-75 hover:opacity-100 hover:scale-105 duration-200 mr-5 '><LazyLoadImage effect='blur'  src="https://i.ibb.co/kMQxvhq/PHONE.png" alt="PHONE" border="0"/></a>
                </div>
                </div>
      
      

      
        {/* <h2 className='text-[25px] text-center text-[#832e2e]'>Write us Email now</h2> */}
      <form ref={form} onSubmit={sendEmail} className='grid grid-cols-1 text-[#252525]'>
      {/* <label>Name</label */}
      <input type="text" required name="from_name" placeholder='Name' className='p-2 opacity-75 rounded-md focus:opacity-100 mb-4' />
      {/* <label>Email</label */}
      <input type="email" required name="from_email" placeholder='Email' className='p-2 opacity-75 rounded-md  focus:opacity-100 mb-4' />
      {/* <label>Message</label */}
      <textarea name="message" required rows={3} placeholder='Message' className='p-2 opacity-75 rounded-md  focus:opacity-100 mb-4' />
      <input type="submit" value="Send" className='cursor-pointer bg-[#832e2e] mx-12  p-3 active:ring-2 active: ring-offset-2 duration-200 active:ring-red-600 rounded-2xl text-white text-2xl  hover:bg-[#b13f3f] '/>
    </form>
    </div>
  )
}
